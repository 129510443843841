import { Fragment } from 'react';
import dynamic from 'next/dynamic';

import type { NextPage } from 'next';
import { fadeInAnimate, slideInDownAnimate } from 'utils/animation';
import NextLink from 'components/reuseable/links/NextLink';
// -------- custom component -------- //
import { Process1 } from 'components/blocks/process';
import { processList1 } from 'data/process';
import { Director } from 'components/blocks/testimonial';
import { Services22 } from 'components/blocks/services';

import { Portfolio2 } from 'components/blocks/portfolio';
import { FAQ2 } from 'components/blocks/faq';
import { accordionList2 } from 'data/faq'; 
import ContactFooterCombo from 'components/reuseable/ContactFooterCombo';

import SEO from 'components/common/SEO';
import * as All from 'data/keywords';
import useLogVisitor from 'hooks/useLogVisitor.js';

const VideoDI = dynamic(() => import('components/blocks/video/Video'));  
const LocalDI = dynamic(() => import('components/blocks/seo/Local'), { ssr: false });
const InternationalDI = dynamic(() => import('components/blocks/seo/International'), { ssr: false }); 

const details = {
  pageName: 'Molto SEO Agency London',
  categories: 'seo, web design',
  description:
    'Transparent, affordable and effective technical SEO services in London for professionals, agencies and enterprise.',
  parentDirectories: ''
};

const Home: NextPage = () => {
  useLogVisitor();

  return (
    <>
    
      {/* ========== SEO META values ========== */}
      <SEO
        title={`${All.HomeList[0].title} London`}
        description={details.description}
        tag={details.categories}
        urlPT={``}
        urlENPT={``}
        url={`${All.HomeList[0].title.toLowerCase().replace(/ /g, '-')}`}
        image={`img/${All.HomeList[0].title.toLowerCase().replace(/ /g, '-')}.jpg`}
        // updated=""
        // published=""
        // modified=""
      />
      <Fragment>
        <main className="content-wrapper home">
          {/* ========== hero section ========== */}
          <div style={fadeInAnimate('900ms')}>
            <VideoDI
              heading={"Google's best friend"}
              title1="AI"
              title2="SEO"
              title3=""
              movie="1.mp4"
              placeholder="1-bg.jpg"
              button="website-test"
              fontsize1={150}
              fontsize2={150}
              body=""
              intro=" helps companies improve their Google ranking with AI-based SEO. If you want to accelerate
              your business online, we are the experts using AI and proprietary automation tools to boost sales."
            />
          </div>

          <div id="ai-seo"></div>

          {/* ========== AI SEO section ========== */}
          <Process1
            title={``}
            subtitle={`SEO AUTOMATION`}
            body={[
              <p className="lead" key="100">
                {`Our proprietary software downloads the details of every website in your industry, analyses all their keywords, Google positions and backlinks. Then our automation compares this data with your site. Then we outline a short term plan to get all the quick wins, usually local and long-tail. 
                 The resulting increase in ROI would demonstrate the benefit of our medium to long term plan to establish top positions for the defining keywords of your industry.`}
              </p>,
              <p
                className="lead"
                key="101"
              >{` We are passionate about helping our clients succeed in the online world. By combining our knowledge and expertise with the latest technology, we can help you achieve your goals and grow your business.`}</p>,
              <p className="lead text-capitalize" key="102">
                <NextLink
                  className="more hover link-purple"
                  href={`/blog/${All.AI[0].title.toLowerCase().replace(/ /g, '-')}`}
                  title={All.AI[0].title}
                />{' '}
              </p>
            ]}
            list={processList1}
            buttonLink="/#contact"
            buttonTitle="Get in touch"
            displayButton={'d-inline'}
            src={`/img/${All.AI[10].title.toLowerCase().replace(/ /g, '-')}-2.png`}
            alt={All.AI[0].title}
          />

          {/* ========== LOCAL SEO section ========== */}
          <div id="localSEO" className="mt15"></div>
          <LocalDI
            heading={All.localSEOPackages[1].title}
            initialslide={0}
            body="Our local & Technical SEO, Social & PPC services target your exact demographic in the UK."
          />
          {/* ========== INTERNATIONAL SEO section ========== */}
          <InternationalDI
            heading={`${All.HomeList[9].title}s`}
            body={`Our ${All.HomeList[9].title}s have developed multilingual software for the biggest tech companies in
                    the USA. We apply the same methodologies to European site development.`}
          />
          {/* ========== DIRECTOR section ========== */}
          <Director title={All.HomeList[6].title} />

          {/* ========== DEVELOPMENT projects carousel section ========== */}
          <Portfolio2 title={`${All.HomeList[7].title}s`} />

          {/* ========== 10 POINT PROCESS section ========== */}
          <FAQ2
            title1="10 POINT"
            title2="PROCESS"
            movie="technical-seo-agency.webm"
            // movie="https://www.shutterstock.com/shutterstock/videos/1052689403/preview/stock-footage-digital-city-seamless-loop-abstract-d-hologram-render-with-futuristic-matrix-flying-through.webm"
            placeholder="1-bg.jpg"
            body=""
            // body={`Molto SEO has developed a 10 Point PROCESS to stay focused only on the on-page, off-page and ${All.HomeList[8].title} that really affect your search results.
            // The bottom line is AI can help you create better optimised content that your readers will love and engage with.`}
            subtitle={All.HomeList[8].title}
            accordion={accordionList2}
            titleColor="muted"
            fontsize1={110}
            fontsize2={100}
          />

          {/* ========== Website SEO Audit Services ========== */}
          <Services22 title="" wordCloud={All} />
        </main>

        <ContactFooterCombo location="london" />
      </Fragment>
    </>
  );
};

export default Home;
